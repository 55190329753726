import "./Main.css";
import foto from "../img/foto.jpg";
import loro from "../img/loro.png";
import monte from "../img/sol.png";
import linkedin from "../img/linkedin.svg";
import css from "../img/css.svg";
import html5 from "../img/html5.svg";
import js from "../img/js.svg";
import sass from "../img/sass.svg";
import code from "../img/code.svg";
import figma from "../img/figma.svg";
import node from "../img/node.svg";
import react from "../img/react.svg";
import bootstrap from "../img/bootstrap.svg";
import flecharight from "../img/flecharight.png";
import NavBar from "../components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useState } from "react";

function App() {
  const [works, setWorks] = useState(false);
  const handleWorks = () => {
    setWorks(!works);
  };

  return (
    <div className="Principal">
      <div onClick={handleWorks} className="check">
        Lets boost your business, check-out my works and find your style!
      </div>
      <div className="fondoPic"></div>
      <div class="firstContainer">
        <img className="yo" src={foto} />
        <div className="fresh"></div>
        <div className="tits">
          <div className="titsIn">
            <h1>
              J.Botella <img className="loro" src={loro} />
            </h1>
            <a
              className="alinkedin"
              href="https://es.linkedin.com/in/javier-botella-lecanda-9b036565"
              target="_blank" // Agrega esta línea
              rel="noopener noreferrer" // También es recomendable agregar esto por motivos de seguridad
            >
              <img className="linkedin" src={linkedin} alt="LinkedIn" />
            </a>

            <div className="fullWorks">
              <h2>Full-Stack Developer</h2>
              <div className="works">
                <img src={flecharight} className="flecha" />
                {/* <span className="worksBut" onClick={handleWorks}>
                  Works
                </span> */}
                {/* <ul className={`ul ${works ? "visible" : ""}`}>
                  <li>DishMatch</li>
                  <li>Carmen Lecanda</li>
                  <li>Espacio W</li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className=" titsExp">
            <div className="upSideP">
              <img className="monte" src={monte} />
            </div>
            <p></p>
          </div>
        </div>
      </div>
      <div className="palms">
        <img className="icons" src={html5} />
        <img className="icons" src={css} />
        <img className="icons" src={bootstrap} />
        <img className="icons" src={react} />
        <img className="icons" src={js} />
        <img className="icons" src={node} />
        <img className="icons" src={sass} />
        <img className="icons" src={code} />
        <img className="icons" src={figma} />
      </div>
      <div className="back"></div>
      <div className="ufo"></div>
    </div>
  );
}

export default App;

import ReactPlayer from "react-player";
import "./DishMatch.css";
export default function DishMatch() {
  return (
    <div className="mainContDishh">
      {/* <div className="explicando">
        <span>
          Dishmatch es un aplicación web para optimizar la gestion de pedidos en
          hosteleria
        </span>
        <span>Por favor, girar pantalla para ver el video de presentación</span>
        <span>
          Dishmatch is a web application developed to optimize orders management
          in hospitality
        </span>
        <span>Please, Rotate screen to see the presentation video.</span>
      </div> */}
      <ReactPlayer
        url={require("../img/DishMatch/DishMatchok.mp4")}
        playing
        controls
        width="100%"
        height="100%"
      />
    </div>
  );
}

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./NavBar.css"; // Importa tu hoja de estilos de NavBar
import { NavLink } from "react-router-dom";

export default function NavBar() {
  // Obtener la ruta actual
  const location = useLocation();

  const [visible, setVisible] = useState(false);

  // Determinar el estilo o clase CSS en función de la ruta
  let navBarStyle = "navBarDefault"; // Estilo predeterminado
  let but = "ButDefault";

  // Asignar las clases CSS correspondientes a los NavLink
  let navLinkCLG = "navLinkDefault";
  let navLinkGlowSite = "navLinkDefault";
  let navLinkDishMatch = "navLinkDefault";
  let navLinkLedens = "navLinkDefault";
  let navLinkMain = "navLinkDefault";

  if (location.pathname === "/CLG") {
    navBarStyle = "navBarCLG"; // Aplicar estilo específico para /CLG
    navLinkCLG = "navLinkCLG"; // Aplicar estilo específico para /CLG
  } else if (location.pathname === "/") {
    navBarStyle = `navBarMain ${visible ? "visibleNav" : ""}`;
    but = "WBut";
    navLinkMain = "navLinkMain"; // Aplicar estilo específico para la ruta principal
  } else if (location.pathname === "/DishMatch") {
    navBarStyle = "navBarDishMatch";
    navLinkDishMatch = "navLinkDishMatch"; // Aplicar estilo específico para /DishMatch
  } else if (location.pathname === "/Ledens") {
    navBarStyle = "navBarLedens";
    navLinkLedens = "navLinkLedens"; // Aplicar estilo específico para /DishMatch
  } else if (location.pathname === "/GlowSite") {
    navBarStyle = "navBarGlowSite";

    navLinkGlowSite = "navLinkGlowSite"; // Aplicar estilo específico para /GlowSite
  }

  return (
    <div>
      <div onClick={() => setVisible(!visible)} className={but}>
        Works
      </div>
      <nav className={navBarStyle}>
        <NavLink to="/CLG" className={navLinkCLG}>
          Carmen Lecanda
        </NavLink>
        <NavLink to="/DishMatch" className={navLinkDishMatch}>
          DishMatch
        </NavLink>
        <NavLink to="/GlowSite" className={navLinkGlowSite}>
          GlowSite
        </NavLink>
        <NavLink to="/Ledens" className={navLinkLedens}>
          Ledens
        </NavLink>
        <NavLink to="/" className={navLinkMain}>
          JBL
        </NavLink>
      </nav>
    </div>
  );
}

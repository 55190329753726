import "./SobreMi.css";
import vela from "../img/vela.jpg";
import { useState } from "react";
import ReactPlayer from "react-player";

export default function SobreMi() {
  const [trayectoria, setTrayectoria] = useState(false);

  const handleTrayec = () => {
    setTrayectoria(!trayectoria);
  };
  return (
    <div className="sobreMi">
      <span onClick={handleTrayec} className="trayeTit">
        Trayectoria
      </span>
      <div className="textos">
        <section className={`dedicado ${trayectoria ? "trayeVisible" : ""}`}>
          He dedicado toda mi vida profesional a la pintura a través de mis
          proyectos y obras propias, y también como docente durante más de 35
          años en la Escuela de Artes y Oficios de Vitoria.
        </section>
      </div>
      <span onClick={handleTrayec} className="expoTit">
        Exposiciones
      </span>
      <span className={`trayectoria ${!trayectoria ? "trayeVisible" : ""}`}>
        <span className="profe">Última exposición: Mi proceso Creativo</span>{" "}
        <br />
        12 de enero a 10 de febrero de 2023 Paraninfo de la Escuela de Artes y
        Oficios de Vitoria Gasteiz
        <br />
        <ReactPlayer
          url="https://youtu.be/TET-5uG0eDc"
          controls
          width="100%"
          height="100%"
        />
        <br />
        <ReactPlayer
          url="https://youtu.be/w5muwlLN-d4"
          controls
          width="100%"
          height="100%"
        />
        <a href="https://www.elcorreo.com/alava/araba/carmen-lecanda-expone-20230112190622-nt.html">
          Artículo en El Correo
        </a>
        <br />
        <a href="https://www.noticiasdealava.eus/cultura/2023/01/28/amba-realiza-visita-especial-muestra-6377154.html">
          Artículo en Noticias de Alava
        </a>
        <br />
        <span className="profe">
          Profesora de la Escuela de Artes y Oficios de Vitoria.
        </span>
        <br />
        Clases de pintura, proyectos creativos, dibujo, modelo natural,
        acuarela, tinta china y retrato desde el año 1985 hasta 2022.
        <br />
        Licenciada en Bellas Artes, especialidad de Pintura en la Universidad
        del País Vasco, Sarriko, Bilbao.
        <br />
        Curso de Dibujo y Pintura en Borgo S. Croce, Palazzo Spinelli,
        Florencia, Italia y Cursos de verano del paisaje al natural.
        <br />
        <span className="profe">Exposiciones anteriores</span>
        <br />
        Mi proceso creativo. Paraninfo de la Escuela de Artes y Oficios de
        Vitoria Gasteiz 12 de enero a 10 febrero 2023.
        <br />
        Sala Luis de Ajuria, Fundación Vital. Postas 13-15. Vitoria. 3 a 26 de
        abril 2019.
        <br />
        Espacio de Arte Van Campen Liem 2, Rue Dicks L-1417 Luxemburgo. 20
        septiembre a 20 de octubre 2018.
        <br />
        Museo de Bellas Artes de Bilbao. Exposición «Los Rochelt. Una familia
        bilbaína vinculada al arte». 16 de mayo a 1 de septiembre de 2014.
        <br />
        Galeria Ederti. Calle Ercilla Bilbao. 1 a 20 de junio 2012.
        <br />
        Galeria Lafondgalleries. 1711E Carson St Southtowns Side. Pittsburgh,
        Pensilvania, EE.UU. Agosto 2008.
        <br />
        Sala de exposiciones Fine Arts Gallery. José Ortega y Gasset 61, Madrid.
        19 a 30 de mayo del 2006.
        <br />
        Espacio de Arte Copernikus, Reckinghausen, Alemania. Mayo 2006.
        <br />
        Galería Tavira. Juan de Ajuriaguerra, Bilbao. Junio 2004.
        <br />
        Espacio de Arte Galería Pietro Readerbroich. Korschenbroich, Dusseldorf,
        Alemania. Julio 2004.
        <br />
        Sala de exposiciones Fundación Bilbao Vizcaya Kutxa. Elcano 20, Bilbao.
        Noviembre 2005.
        <br />
        Espacio expositivo Kino, Escuela de Artes y Oficios. Plaza Conde de
        Peñaforida, Vitoria. Mayo 2004.
        <br />
        Galería Caledonia de Bilbao y Madrid. Ercilla 11, Bilbao, 2002.
        <br />
        Sala de exposiciones Fundación Bizkaia Kutxa. Elcano 20, Bilbao, 2001.
        <br />
        Paraninfo de la Escuela de Artes y Oficios de Vitoria, Conde de
        Peñaflorida. Mayo 1998.
        <br />
        Sala de exposiciones Dato. Calle Dato, Vitoria. Noviembre 1997.
        <br />
        Paraninfo de la Escuela de Artes y Oficios de Vitoria. Mayo 1995.
        <br />
        Expacio expositivo C. Ajuria. Avda Gasteiz Vitoria, 1995.
        <br />
        Galería de Arte Arteta. Iparraguirre. Bilbao. Mayo 1992.
        <br />
        Galería de Arte Arteta. Iparraguirre. Bilbao. Abril 1986.
        <br />
        Sala de exposiciones Luis de Ajuria, selección conmemorativa de pintura.
        General Álava, Vitoria. Noviembre 1985.
        <br />
        Espacio Don Chufo. Iparraguirre 9. Bilbao. Junio 1981.
        <br />
        Sala Aula de Cultura Caja Municipal de Bilbao. Elcano 20. Junio 1981.
      </span>

      <div className="textos2">
        <img src={vela} alt="vela" className="vela" />

        <section className="dedicado2">
          Mis obras reflejan momentos de inspiración, siempre en la naturaleza.
          El proceso nace en el entorno natural, con formatos pequeños, llegando
          poco a poco a una abstracción, donde la forma desaparece. Así elaboro
          los formatos medianos y finalmente la obra grande trabajada en el
          estudio. Es en el formato grande en el que me envuelvo y donde me
          siento bien.
        </section>
      </div>
    </div>
  );
}

import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__text">
        <span className="footer__text__p">
          © 2023 Carmen Lecanda. All rights reserved.
        </span>
      </div>
    </div>
  );
}

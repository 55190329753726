import "./GlowSite.css";
import whiteLogo from "../img/GlowSite/darkLogo.png";
import mail from "../img/GlowSite/mail.png";
import mujer1 from "../img/GlowSite/mujer1.png";
import { useState } from "react";

export default function GlowSite() {
  const [visibleForm, setVisibleForm] = useState(false);

  const handleForm = () => {
    setVisibleForm(!visibleForm);
  };
  return (
    <div className="mainGlow">
      <div className="logoTit">
        <img className="whiteLogoG" src={mujer1} alt="whiteLogo" />
        <span>Your e-Style Magazine</span>
      </div>
      <span className={`bubbleA ${!visibleForm ? "moveBubbleA" : ""}`}>
        <span className={`naked ${!visibleForm ? "formOut" : ""}`}>
          Naked is fun yeh but..
        </span>{" "}
        <span className={`awesome ${!visibleForm ? "awesomeMove" : ""}`}>
          to Glow is
          <br />
          awesome.
        </span>
      </span>
      <span className="bubbleB">Get into Glow</span>
      <ul className="bubbleC">
        <li className="glowList">GlowSite</li>
        <li className="glowList">Profiles</li>
        <li className="glowList">Trends</li>
        <li className="glowList">Style</li>
      </ul>
      <img
        onClick={handleForm}
        className={`mail ${visibleForm ? "moveMail" : ""}`}
        src={mail}
        alt="mail"
      />
      <section className={`formContainerGlow ${visibleForm ? "formOut" : ""}`}>
        <div className="form">
          <section className="formSection">
            <span className="formTit">*Email</span>
            <input className="formInput" type="text"></input>
          </section>
          <section className="formSection">
            <span className="formTit">*Last name</span>
            <input className="formInput" type="text"></input>
          </section>
          <section className="formSection">
            <span className="formTit">*Name</span>
            <input className="formInput" type="text"></input>
          </section>
          <section className="formSection">
            <span className="formTit">*Request</span>
            <input className="formInput" type="text"></input>
          </section>
        </div>
        <button onClick={handleForm} className="contactButton">
          Subscribe
        </button>
      </section>
    </div>
  );
}

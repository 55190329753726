import ReactPlayer from "react-player";
import "./Ledens.css";
export default function Ledens() {
  return (
    <div className="mainContDishh">
      <ReactPlayer
        url={require("../img/Ledens/Ledensok.mp4")}
        playing
        controls
        width="100%"
        height="100%"
      />
    </div>
  );
}

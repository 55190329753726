import "./App.css";
import Main from "./pages/Main";
import DishMatch from "./pages/DishMatch";
import Ledens from "./pages/Ledens";
import CLG from "./pages/CLG/CLG";
import NotFound from "./pages/NotFound";
import GlowSite from "./pages/GlowSite";
import NavBar from "./components/NavBar"; // Importa el componente NavBar
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/CLG" element={<CLG />} />
        <Route path="/DishMatch" element={<DishMatch />} />
        <Route path="/Ledens" element={<Ledens />} />
        <Route path="/GlowSite" element={<GlowSite />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

import "./MisObras.css";
import veleros from "../img/veleros.png";
import fresh from "../img/fresh.png";
import fiesta from "../img/fiesta.png";
import power from "../img/power.png";
import estudio from "../img/estudio.png";
import blues from "../img/azules.png";
import ropa from "../img/ropa.jpg";
import blues2 from "../img/chia.png";
import bombay from "../img/bombay.jpg";
import fragua from "../img/fragua.jpg";
import vista from "../img/vista.jpg";
import gugen from "../img/gugen.png";

import { useState } from "react";

export default function MisObras() {
  const [moveVelero, setMoveVelero] = useState(false);
  const [moveFresh, setMoveFresh] = useState(false);
  const [moveFiesta, setMoveFiesta] = useState(false);
  const [movePower, setMovePower] = useState(false);
  const [moveBlues, setMoveBlues] = useState(false);
  const [moveGugen, setMoveGugen] = useState(false);
  const [moveVista, setMoveVista] = useState(false);
  const [moveFragua, setMoveFragua] = useState(false);
  const [moveBombay, setMoveBombay] = useState(false);
  const [moveRopa, setMoveRopa] = useState(false);
  const [moveBlues2, setMoveBlues2] = useState(false);

  const [oscuroFondo, setOscuroFondo] = useState(false);
  const [oscuroVelero, setOscuroVelero] = useState(false);
  const [oscuroFresh, setOscuroFresh] = useState(false);
  const [oscuroFiesta, setOscuroFiesta] = useState(false);
  const [oscuroPower, setOscuroPower] = useState(false);
  const [oscuroBlues, setOscuroBlues] = useState(false);
  const [oscuroGugen, setOscuroGugen] = useState(false);
  const [oscuroVista, setOscuroVista] = useState(false);
  const [oscuroFragua, setOscuroFragua] = useState(false);
  const [oscuroBombay, setOscuroBombay] = useState(false);
  const [oscuroRopa, setOscuroRopa] = useState(false);
  const [oscuroBlues2, setOscuroBlues2] = useState(false);

  const handleMoveFiesta = () => {
    setMoveFiesta(!moveFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroPower(!oscuroPower);
    setOscuroFondo(!oscuroFondo);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);
    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };

  const handleMoveVelero = () => {
    setMoveVelero(!moveVelero);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroFresh(!oscuroFresh);
    setOscuroPower(!oscuroPower);
    setOscuroFondo(!oscuroFondo);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);

    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveFresh = () => {
    setMoveFresh(!moveFresh);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroPower(!oscuroPower);
    setOscuroFondo(!oscuroFondo);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);

    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMovePower = () => {
    setMovePower(!movePower);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);

    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveBlues = () => {
    setMoveBlues(!moveBlues);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroGugen(!oscuroGugen);

    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveGugen = () => {
    setMoveGugen(!moveGugen);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroBlues(!oscuroBlues);
    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveVista = () => {
    setMoveVista(!moveVista);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveFragua = () => {
    setMoveFragua(!moveFragua);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);
    setOscuroVista(!oscuroVista);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveBombay = () => {
    setMoveBombay(!moveBombay);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);
    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroRopa(!oscuroRopa);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveRopa = () => {
    setMoveRopa(!moveRopa);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);
    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroBlues2(!oscuroBlues2);
  };
  const handleMoveBlues2 = () => {
    setMoveBlues2(!moveBlues2);
    setOscuroFiesta(!oscuroFiesta);
    setOscuroVelero(!oscuroVelero);
    setOscuroFresh(!oscuroFresh);
    setOscuroFondo(!oscuroFondo);
    setOscuroPower(!oscuroPower);
    setOscuroBlues(!oscuroBlues);
    setOscuroGugen(!oscuroGugen);
    setOscuroVista(!oscuroVista);
    setOscuroFragua(!oscuroFragua);
    setOscuroBombay(!oscuroBombay);
    setOscuroRopa(!oscuroRopa);
  };

  return (
    <div className="secondPageCLG">
      <span className={`elige ${oscuroFondo ? "estudioBackOut" : ""}`}>
        Elige un cuadro
      </span>
      <img
        src={estudio}
        className={`estudioBack ${oscuroFondo ? "estudioBackOut" : ""}`}
      />
      <div className="cuadrosContainer">
        <img
          onClick={handleMoveVelero}
          src={veleros}
          alt="veleros"
          className={`veleros ${moveVelero ? "cuadroMoveVeleros" : ""} ${
            oscuroVelero ? "cuadrosBackOut" : ""
          } `}
        />

        <img
          onClick={handleMoveFresh}
          src={fresh}
          alt="fresh"
          className={`freshM ${moveFresh ? "cuadroMoveFresh" : ""}${
            oscuroFresh ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveFiesta}
          src={fiesta}
          alt="fiesta"
          className={`fiesta ${moveFiesta ? "cuadroMoveFiesta" : ""}${
            oscuroFiesta ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMovePower}
          src={power}
          alt="power"
          className={`power ${movePower ? "cuadroMovePower" : ""}${
            oscuroPower ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveBlues}
          src={blues}
          alt="blues"
          className={`blues ${moveBlues ? "cuadroMoveBlues" : ""}${
            oscuroBlues ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveGugen}
          src={gugen}
          alt="gugen"
          className={`gugen ${moveGugen ? "cuadroMoveGugen" : ""}${
            oscuroGugen ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveVista}
          src={vista}
          alt="vista"
          className={`vista ${moveVista ? "cuadroMoveVista" : ""}${
            oscuroVista ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveFragua}
          src={fragua}
          alt="fragua"
          className={`fragua ${moveFragua ? "cuadroMoveFragua" : ""}${
            oscuroFragua ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveBombay}
          src={bombay}
          alt="bombay"
          className={`bombay ${moveBombay ? "cuadroMoveBombay" : ""}${
            oscuroBombay ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveRopa}
          src={ropa}
          alt="ropa"
          className={`ropa ${moveRopa ? "cuadroMoveRopa" : ""}${
            oscuroRopa ? "cuadrosBackOut" : ""
          }`}
        />
        <img
          onClick={handleMoveBlues2}
          src={blues2}
          alt="blues2"
          className={`blues2 ${moveBlues2 ? "cuadroMoveBlues2" : ""}${
            oscuroBlues2 ? "cuadrosBackOut" : ""
          }`}
        />
      </div>
    </div>
  );
}

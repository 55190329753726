import React, { useState } from "react";
import imageData from "../components/images.json"; // Asegúrate de proporcionar la ruta correcta al archivo JSON
import "./Cuadros.css";
function Cuadros() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + imageData.images.length) % imageData.images.length
    );
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageData.images.length);
  };

  return (
    <div className="carousel">
      <button onClick={prevImage}>Anterior</button>
      <div className="carousel-container">
        {imageData.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            style={{ display: index === currentIndex ? "block" : "none" }}
          />
        ))}
      </div>
      <button onClick={nextImage}>Siguiente</button>
    </div>
  );
}

export default Cuadros;

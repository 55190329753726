import "./Clases.css";
import clase1 from "../img/clase1.jpg";
import clase2 from "../img/clase2.jpg";
import clase3 from "../img/clase3.jpg";
import clase4 from "../img/clase4.jpg";
import clase5 from "../img/clase5.jpg";

export default function Clases() {
  return (
    <div className="escuela">
      <span className="misClases">Mis Clases</span>
      <div className="escuelaFotosContainer">
        <img className="escuelaFotos" src={clase1} alt="Clase 1" />
        {/* <img className="escuelaFotos" src={clase4} alt="Clase 4" /> */}
        <img className="escuelaFotos" src={clase2} alt="Clase 2" />
        {/* <img className="escuelaFotos" src={clase3} alt="Clase 3" /> */}
        <img className="escuelaFotos" src={clase5} alt="Clase 5" />
      </div>
    </div>
  );
}
